import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"
import HubspotForm from "components/hubspot"

const StyledSubscription = styled.section`
  padding: 68px 0;

  ${breakpoint.medium`
    padding: 104px 0;
  `}
  .msg-confirm {
    margin: 10px;
  }

  .title {
    margin-bottom: 40px;
    text-align: center;

    ${breakpoint.medium`
      margin-bottom: 60px;
    `}
    h2 {
      padding-bottom: 1rem;
    }

  }

 

`

const SubscriptionForm = () => {

  return (
    <StyledSubscription>
      <Container data-aos="fade-up">
        <div className="subscription__form">
        <HubspotForm
            formId='62567609-bb2c-4ac8-9f22-9721dd752d3e'
          />
        </div>
      </Container>
    </StyledSubscription>
  )
}

export default SubscriptionForm
